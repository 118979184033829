import { faGear, faList, faSms, faUser } from '@fortawesome/pro-regular-svg-icons';
import { Checkbox } from '@intility/bifrost-react';
import Nav from '@intility/bifrost-react/Nav';
import { IntilityAppsReactQuery } from '@intility/platform-toolkit-msal/react-query';
import '@intility/platform-toolkit/css/IntilityApps.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, Outlet } from 'react-router-dom';

import { useMsalRouterIntegration } from '~/auth/useMsalRouterIntegration';
import { routes } from '~/routes/config';
import { useCurrentStep, useSmsStoreActions } from '~/stores/smsStore';
import { cn } from '~/utils/clsx';
import { env } from '~/utils/env';
import i18n from '~/utils/i18n.config';
import { PublishBanners } from './PublishBanners';
const Navigation = () => {
  const appVersion = env.version;
  const isStaging = window.location.hostname.includes('sms-staging');
  const versionText = !isStaging ? `v${appVersion}` : `v${appVersion} - Staging`;

  useMsalRouterIntegration();
  const { t } = useTranslation();
  const { setCurrentStep } = useSmsStoreActions();
  const currentStep = useCurrentStep();
  const locale = i18n.language;

  const [sideCollapsed, setSideCollapsed] = useState(false);
  return (
    <Nav
      title='Intility SMS'
      logo={
        <Link to={routes.create.path} onClick={() => setCurrentStep('welcome')}>
          <Nav.Logo
            logo={
              <img
                src={new URL('/src/assets/intility-sms.png', import.meta.url).href}
                alt={'Intility SMS logo'}
              />
            }
          >
            Intility SMS
            {appVersion && <span className='bf-label-description ml-bfs-4'>{versionText}</span>}
          </Nav.Logo>
        </Link>
      }
      sideProps={{
        collapsed: sideCollapsed,
        onCollapsedChange: collapsed => setSideCollapsed(collapsed),
      }}
      top={
        <>
          <IntilityAppsReactQuery />
          <Nav.Group icon={faUser}>
            <div className='my-bfs-16'>
              <div className='mx-bfs-24 text-xs text-bfc-base-c-2'>{t('Language')}</div>
              <Checkbox
                type='radio'
                name='language'
                label='English'
                checked={locale === 'en-US'}
                onChange={() => i18n.changeLanguage('en-US')}
              />
              <Checkbox
                type='radio'
                name='language'
                label='Norsk'
                checked={locale === 'nb-NO'}
                onChange={() => i18n.changeLanguage('nb-NO')}
              />
            </div>
          </Nav.Group>
        </>
      }
      side={
        <>
          <NavLink
            className={({ isActive }) => cn({ active: isActive && currentStep !== 'welcome' })}
            to={routes.create.path}
            onClick={() => setCurrentStep('recipients')}
            end
          >
            <Nav.Item icon={faSms}>{t('Send SMS')}</Nav.Item>
          </NavLink>

          <NavLink to={routes.history.path}>
            <Nav.Item icon={faList}>{t('History')}</Nav.Item>
          </NavLink>

          <NavLink to={routes.settings.path}>
            <Nav.Item icon={faGear}>{t('Settings')}</Nav.Item>
          </NavLink>
        </>
      }
    >
      <div className='flex flex-col gap-bfs-8 px-bfs-12 pt-bfs-12'>
        <PublishBanners />
      </div>

      <Outlet />
    </Nav>
  );
};

export default Navigation;
