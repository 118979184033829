import { Modal } from '@intility/bifrost-react';
import React, { createContext, useCallback, useMemo, useState, type JSX } from 'react';

interface Props {
  children: React.ReactNode;
}

export type HandleModalType = {
  content?: JSX.Element;
  header?: string;
};

interface ModalContextProps {
  modal?: boolean;
  handleModal: (props: HandleModalType) => void;
  modalContent?: JSX.Element;
  modalHeader?: string;
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalContext = createContext<ModalContextProps>({} as ModalContextProps);

const ModalProvider = ({ children }: Props) => {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState<JSX.Element>(<></>);
  const [modalHeader, setModalHeader] = useState('Default header');

  const handleModal = useCallback(
    ({ content = <></>, header = '' }: HandleModalType) => {
      setModal(!modal);
      setModalContent(content);
      setModalHeader(header);
    },
    [modal],
  );

  const modalValue: ModalContextProps = useMemo(
    () => ({
      modal,
      handleModal,
      modalContent,
      modalHeader,
      setModal,
    }),
    [modal, modalContent, modalHeader, setModal, handleModal],
  );

  return (
    <ModalContext.Provider value={modalValue}>
      <Modal
        className={'[&>header>h1]:text-bffs-h3 sm:[&>header>h1]:text-bffs-h2'}
        onRequestClose={() => handleModal({})}
        isOpen={modal}
        header={modalHeader}
      >
        {modal && modalContent}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
