import Tabs from '@intility/bifrost-react/Tabs';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerContext } from '~/context/DrawerContext';
import {
  useSelectedExternalPeople,
  useSelectedGraphGroups,
  useSelectedGraphUsers,
} from '~/stores/smsStore';
import { ViewOnlyProvider } from '../../context/ViewOnlyContext';
import { SelectedExternalPeopleTable } from './SelectedExternalPeopleTable';
import { SelectedGraphGroupsTable } from './SelectedGraphGroupsTable';
import { SelectedGraphUsersTable } from './SelectedGraphUsersTable';

interface SelectedRecipientsDrawerProps {
  viewOnly?: boolean;
}

export const SelectedRecipientsDrawer = ({ viewOnly = false }: SelectedRecipientsDrawerProps) => {
  const { t } = useTranslation('create');
  const { handleDrawer } = useContext(DrawerContext);
  const selectedGroups = useSelectedGraphGroups();
  const selectedGraphUsers = useSelectedGraphUsers();
  const selectedExternalPeople = useSelectedExternalPeople();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const allRecipientsGroups = [...selectedGroups, ...selectedGraphUsers, ...selectedExternalPeople];

  const tabs = useMemo(
    () => [
      {
        title: t('Groups'),
        content: <SelectedGraphGroupsTable />,
        getContentLength: () => selectedGroups.length,
      },
      {
        title: t('People'),
        content: <SelectedGraphUsersTable />,
        getContentLength: () => selectedGraphUsers.length,
      },
      {
        title: t('External'),
        content: <SelectedExternalPeopleTable />,
        getContentLength: () => selectedExternalPeople.length,
      },
    ],
    [t, selectedGroups.length, selectedGraphUsers.length, selectedExternalPeople.length],
  );

  useEffect(() => {
    if (!allRecipientsGroups.length) {
      handleDrawer({});
      //navigate to next populated tab if current tab is empty
    } else if (tabs[currentTab]?.getContentLength() === 0) {
      const nextTab = tabs.slice(currentTab + 1).findIndex(tab => tab.getContentLength() > 0);
      setCurrentTab(nextTab !== -1 ? nextTab + currentTab + 1 : 0);
    }
  }, [tabs, currentTab, allRecipientsGroups.length, handleDrawer]);

  return (
    <ViewOnlyProvider viewOnly={viewOnly}>
      <Tabs noBackground>
        {tabs.map((tab, index) =>
          tab.getContentLength() > 0 ? (
            <Tabs.Item
              noPadding
              active={currentTab === index}
              onClick={() => setCurrentTab(index)}
              key={tab.title}
              content={tab.content}
            >
              {tab.title}
            </Tabs.Item>
          ) : null,
        )}
      </Tabs>
    </ViewOnlyProvider>
  );
};
