import type { SmsRecipientDto } from '@intility/portal-sms-shared-utils';

import { Table } from '~/components/table/Table';
import { columns } from './SmsRecipientColumns';

interface SmsRecipientsDrawerProps {
  recipients: SmsRecipientDto[];
}

export const SmsRecipientsDrawer = ({ recipients }: SmsRecipientsDrawerProps) => {
  return (
    <Table
      data={recipients}
      columns={columns}
      meta={{
        noBorder: true,
      }}
    />
  );
};
