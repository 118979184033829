import { Badge, FormatDate } from '@intility/bifrost-react';
import type { MessageDeliveryDto } from '@intility/portal-sms-shared-utils';
import { createColumnHelper } from '@tanstack/react-table';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerContext } from '~/context/DrawerContext';
import { cleanName } from '~/utils/format';
import { SmsRecipientsDrawer } from './SmsRecipientsDrawer';

const columnHelper = createColumnHelper<MessageDeliveryDto>();

enum MessageDeliveryStatus {
  SENDING = "sending",
  SENT = "sent",
  FAILED = "failed",
  ACCEPTED = "accepted",
  SCHEDULED = "scheduled",
  CANCELED = "canceled",
  QUEUED = "queued",
  DELIVERED = "delivered",
  UNDELIVERED = "undelivered",
  RECEIVING = "receiving",
  RECEIVED = "received",
  READ = "read"
}

const StatusBadgeState = {
  [MessageDeliveryStatus.ACCEPTED]: 'success',
  [MessageDeliveryStatus.DELIVERED]: 'success',
  [MessageDeliveryStatus.READ]: 'success',
  [MessageDeliveryStatus.RECEIVED]: 'success',
  [MessageDeliveryStatus.RECEIVING]: 'success',
  [MessageDeliveryStatus.SENT]: 'success',
  [MessageDeliveryStatus.SENDING]: 'neutral',
  [MessageDeliveryStatus.QUEUED]: 'warning',
  [MessageDeliveryStatus.SCHEDULED]: 'warning',
  [MessageDeliveryStatus.CANCELED]: 'alert',
  [MessageDeliveryStatus.FAILED]: 'alert',
  [MessageDeliveryStatus.UNDELIVERED]: 'alert',
} as const;

export const columns = [
  columnHelper.accessor('messageSentAt', {
    enableGlobalFilter: false,
    header: function Header() {
      const { t } = useTranslation('history');

      return t('Sent');
    },
    cell: function Cell(cell) {
      const { t } = useTranslation('history');

      const dateString = cell.getValue<null | string>();
      const date = dateString ? new Date(dateString) : undefined;

      if (!date) return t('Not sent');

      return <FormatDate show='datetime' date={date} />;
    },
  }),
  columnHelper.accessor('message', {
    id: 'message',
    header: function Header() {
      const { t } = useTranslation('history');

      return t('Message');
    },
    meta: {
      className: 'max-w-[200px] truncate',
    },
  }),
  columnHelper.accessor('recipients', {
    enableGlobalFilter: false,
    header: function Header() {
      const { t } = useTranslation('history');

      return t('Recipients');
    },
    cell: function Cell(cell) {
      const { t } = useTranslation('history');
      const { handleDrawer } = useContext(DrawerContext);

      const recipients = cell.getValue();
      const recipientsLength = recipients.length;

      const translatedPerson = t('person');
      const translatedPeople = t('people');
      const personOrPeople = recipientsLength === 1 ? translatedPerson : translatedPeople;

      return (
        <button
          className='bf-link'
          onClick={() =>
            handleDrawer({
              header: t('Recipients'),
              content: <SmsRecipientsDrawer recipients={recipients} />,
            })
          }
        >
          {recipientsLength} {personOrPeople}
        </button>
      );
    },
    meta: {
      fromSize: 'medium',
    },
  }),
  columnHelper.accessor('companyName', {
    enableGlobalFilter: false,
    header: function Header() {
      const { t } = useTranslation('history');

      return t('Sender');
    },
    meta: {
      fromSize: 'medium',
    },
  }),
  columnHelper.accessor('authorName', {
    id: 'authorName',
    header: function Header() {
      const { t } = useTranslation('history');

      return t('Author');
    },
    cell: function Cell(cell) {
      return cleanName(cell.row.original.authorName);
    },
    meta: {
      fromSize: 'medium',
    },
  }),
  columnHelper.accessor('status', {
    enableGlobalFilter: false,
    header: function Header() {
      const { t } = useTranslation('history');

      return t('Status');
    },
    cell: function Cell(cell) {
      const { t } = useTranslation('history');
      const status = cell.row.original.status;

      const friendlyStatuses: Record<string, string> = {
        [MessageDeliveryStatus.SENT]: t('Sent'),
        [MessageDeliveryStatus.FAILED]: t('Failed'),     
      } as const;

      return <Badge state={StatusBadgeState[status]}>{friendlyStatuses[status]}</Badge>;
    },
    meta: {
      fromSize: 'small',
    },
  }),
];
