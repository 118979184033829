import { Grid, Message } from '@intility/bifrost-react';
import { useTranslation } from 'react-i18next';

import { ContentLayout } from '~/components/layout/ContentLayout';
import { HistoryOptions } from '../components/HistoryOptions';
import { HistoryTable } from '../components/HistoryTable';


export const History = () => {
  const { t } = useTranslation('history');

  return (
    <ContentLayout
      noPadding
      pageTitle={t('History')}
      pageDescription={t("View your company's SMS history.")}
      className='max-w-screen-xl p-bfs-16 md:px-bfs-80 md:py-bfs-40'
    >
      <Message  header={t('Information')}>
      <p className='whitespace-pre-line'>{t('HistoryDisclaimer')}</p>
      </Message>
      <Grid>
        <HistoryOptions />
        <HistoryTable />
      </Grid>
    </ContentLayout>
  );
};
